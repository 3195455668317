<template>
  <v-container fluid>
      <h2 class="white--text text-center mb-5">Coparticipação</h2>
    <v-sheet class="slide-group mx-auto mb-4" color="transparent">
        <v-slide-group multiple>
            <v-slide-item>
                <div>
                    <v-card-subtitle class="pa-0 ma-0 pl-1 grey--text">Competência</v-card-subtitle>
                    <v-select fixed v-model="activeFilter" :items="filters" label="Este mês" background-color="transparent" small-chips dense solo class="custom mt-0 pt-0" append-icon="fas fa-caret-down"/>
                </div>
            </v-slide-item>

            <v-slide-item>
                <div class="ml-4">
                    <v-card-subtitle class="pa-0 ma-0 pl-1 grey--text">Desconto</v-card-subtitle>
                    <v-chip small class="mt-0 pt-0">R$ 870,00</v-chip>
                </div>
            </v-slide-item>

            <v-slide-item>
                <div class="ml-4">
                    <v-card-subtitle class="pa-0 ma-0 pl-1 grey--text">Copart.</v-card-subtitle>
                    <v-chip small class="mt-0 pt-0">R$ 870,00</v-chip>
                </div>
            </v-slide-item>

            <v-slide-item>
                <div class="ml-4">
                    <v-card-subtitle class="pa-0 ma-0 pl-1 grey--text">Total Cobrado</v-card-subtitle>
                    <v-chip small class="mt-0 pt-0">R$ 1360,00</v-chip>
                </div>
            </v-slide-item>
        </v-slide-group>
    </v-sheet>

    <template v-for="(event, index) of usage">
        <UsageCard :usage="event" :key="index" class="mb-3"/>
    </template>
  </v-container>
</template>

<script>
import UsageCard from './usage-card';
export default {
    name: 'ExtratoDeUtilizacao',
    components: { UsageCard },
    data: () => ({
        usage: [],
        activeFilter: 'Este mês',
        filters: ['Este mês'],
    }),
    mounted() {
        this.show = true;
    },
};

</script>

<style lang="scss">

/* select */
.custom.v-text-field > .v-input__control > .v-input__slot:before,
.custom.v-text-field > .v-input__control > .v-input__slot:after {
  border-style: none !important;
}
.custom.v-select,
.custom.v-select div {
  width: auto !important;
  margin-top: -3px;
}
.custom.v-select .v-text-field__details,
.custom.v-select .v-select__slot .v-select__selections input {
  display: none !important;
}
.custom.v-select .v-select__slot .v-select__selections .v-chip--select {
  padding-right: 50px !important;
}
.custom.v-select .v-select__slot .v-input__append-inner {
  margin-left: -40px !important;
  z-index: 1 !important;
}
.custom.v-select .v-input__slot {
  box-sizing: unset !important;
  -webkit-box-shadow: unset !important;
  padding: 0 !important;
  margin: 0 !important;
}

</style>
