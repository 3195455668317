<template>
  <v-card class="mb-4 purpleclean">
    <v-card-title class="text-center pa-0">
      <v-card-subtitle class="pa-1 pl-2 white--text">
        <b>Coparticipação</b><br />R$ {{ usage.partakingPrice }}
      </v-card-subtitle>

      <v-row v-if="usage.partakingDiscount !== '0,00'">
        <v-spacer />
        <v-icon color="secondary">fas fa-trophy</v-icon>
        <v-spacer />
      </v-row>
      <v-spacer v-else />

      <v-card-subtitle class="pa-1 pr-2 white--text">
        <b>Data do evento</b><br />{{ usage.eventDate }}
      </v-card-subtitle>
    </v-card-title>

    <v-card-title class="pa-0 text-center white--text">
      <v-spacer />
      <v-card-subtitle class="pa-0 white--text">
        <b>{{ usage.patient }}</b
        ><br />
        {{ usage.procedure }}<br />
        {{ usage.provider }}<br />
      </v-card-subtitle>
      <v-spacer />
    </v-card-title>

    <v-card-title class="pt-0 pb-0 white--text">
      <v-spacer />
      <h3>R$ {{ usage.procedurePrice }}</h3>
      <v-spacer />
    </v-card-title>

    <v-card-title class="text-center pa-0 white--text">
      <v-card-subtitle class="pa-1 pl-2 white--text">
        <b>Desconto</b><br />R$ {{ usage.partakingDiscount }}
      </v-card-subtitle>

      <v-spacer />
      <v-card-subtitle class="pa-1 pr-2 white--text">
        <b>Valor cobrado</b><br />R$ {{ usage.priceTaken }}
      </v-card-subtitle>
    </v-card-title>
  </v-card>
</template>

<script>
export default {
  name: "UsageCard",
  props: {
    usage: {
      type: Object,
      required: true,
    },
  },
};
</script>
